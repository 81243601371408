.modern-header,
.modern-row {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  min-width: 480px;

  .column {
    flex: 1;
    min-width: 0;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .sort-direction {
        cursor: pointer;
      }
      .sort-direction.active {
        color: @primary-color;
      }
    }
  }
}

.modern-list {
  .ant-list-item {
    border: none;
    padding: 0px;
  }
}

@text-color: #707070;@text-color-secondary: #a9a9a9;@primary-color: #557cb7;@heading-color: #707070;@tabs-horizontal-margin: 0 80px 0 0;@tabs-horizontal-margin-rtl: 0 0 0 80px;@tabs-bar-margin: 0 0 30px 0;@form-vertical-label-padding: 0;@badge-status-size: 10px;@menu-item-vertical-margin: 0px;