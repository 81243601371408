@import '../../../../../../styles/breakpoints.less';

@modal-breakpoint: 991px;

@breakpoint-xl: 1599px;

.modal-invite {
  .ant-modal-body {
    background: linear-gradient(30deg, #557cb7, #a1c8fe);
    min-height: 90vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    @media screen and (max-width: @modal-breakpoint) {
      flex-direction: column;
    }

    .invite-qr-code {
      width: 30vw !important;
      height: 30vw !important;
      background-color: #fff;
      border-radius: 30px;

      @media screen and (max-width: @modal-breakpoint) {
        width: 50vw !important;
        height: 50vw !important;
      }
    }

    .container-logo {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-height: 80px;
        @media screen and (max-width: @breakpoint) {
          max-height: 50px;
        }
      }

      .title {
        font-size: 5.5em;
        font-weight: bold;
        line-height: 60px;
        margin: 0px;
        color: #fff;
        @media screen and (max-width: @breakpoint) {
          font-size: 4.5em;
        }
      }
    }

    .container-info {
      flex: 1;
      color: #fff;
      overflow: hidden;
      max-width: 800px;

      .manual-link {
        font-size: 3.5vw;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;

        @media screen and (min-width: @breakpoint-xl) {
          font-size: 60px;
        }

        @media screen and (max-width: @breakpoint) {
          font-size: 6vw;
        }
      }
    }
  }
}

@text-color: #707070;@text-color-secondary: #a9a9a9;@primary-color: #557cb7;@heading-color: #707070;@tabs-horizontal-margin: 0 80px 0 0;@tabs-horizontal-margin-rtl: 0 0 0 80px;@tabs-bar-margin: 0 0 30px 0;@form-vertical-label-padding: 0;@badge-status-size: 10px;@menu-item-vertical-margin: 0px;