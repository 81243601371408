body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
hr {
  border-top-width: 0pt;
  border-style: solid;
  border-color: #9f9f9f;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.content {
  padding: 120px 30px;
}
@media screen and (max-width: 767px) {
  .content {
    padding: 60px 30px;
  }
}
.dropdown-shadow {
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
}
.finvote-collapse {
  background-color: transparent;
}
.finvote-collapse .ant-collapse-item:last-child {
  border-bottom: 0px;
}
body {
  font-size: 1em;
}
p {
  font-size: 1em;
}
h1 {
  font-size: 2.7em;
  color: #707070;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  h1 {
    font-size: 1.8em;
  }
}
h2 {
  font-size: 2.23em;
  color: #707070;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  h2 {
    font-size: 1.5em;
  }
}
h3 {
  font-size: 1.81em;
  color: #707070;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  h3 {
    font-size: 1.2em;
  }
}
h4 {
  font-size: 1.3em;
  color: #707070;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  h4 {
    font-size: 1em;
  }
}
.text-secondary {
  color: #9f9f9f;
}
.text-success {
  color: #3acc6c !important;
}
.text-alert {
  color: #bf0000 !important;
}
.margin-bottom-xl {
  margin-bottom: 120px;
}
@media screen and (max-width: 767px) {
  .margin-bottom-xl {
    margin-bottom: 80px;
  }
}
.margin-bottom-lg {
  margin-bottom: 60px;
}
@media screen and (max-width: 767px) {
  .margin-bottom-lg {
    margin-bottom: 40px;
  }
}
.margin-bottom-md {
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .margin-bottom-md {
    margin-bottom: 20px;
  }
}
.margin-bottom-sm {
  margin-bottom: 15px;
}
@media screen and (max-width: 767px) {
  .margin-bottom-sm {
    margin-bottom: 10px;
  }
}
.margin-bottom-xs {
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .margin-bottom-xs {
    margin-bottom: 6px;
  }
}
.padding-bottom-xl {
  padding-bottom: 120px;
}
@media screen and (max-width: 767px) {
  .padding-bottom-xl {
    padding-bottom: 80px;
  }
}
.padding-bottom-lg {
  padding-bottom: 60px;
}
@media screen and (max-width: 767px) {
  .padding-bottom-lg {
    padding-bottom: 40px;
  }
}
.padding-bottom-md {
  padding-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .padding-bottom-md {
    padding-bottom: 20px;
  }
}
.padding-bottom-sm {
  padding-bottom: 15px;
}
@media screen and (max-width: 767px) {
  .padding-bottom-sm {
    padding-bottom: 10px;
  }
}
.padding-bottom-xs {
  padding-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .padding-bottom-xs {
    padding-bottom: 6px;
  }
}
.padding-right-xl {
  padding-right: 120px;
}
@media screen and (max-width: 767px) {
  .padding-right-xl {
    padding-right: 80px;
  }
}
.padding-right-lg {
  padding-right: 60px;
}
@media screen and (max-width: 767px) {
  .padding-right-lg {
    padding-right: 40px;
  }
}
.padding-right-md {
  padding-right: 30px;
}
@media screen and (max-width: 767px) {
  .padding-right-md {
    padding-right: 20px;
  }
}
.padding-right-sm {
  padding-right: 15px;
}
@media screen and (max-width: 767px) {
  .padding-right-sm {
    padding-right: 10px;
  }
}
.padding-right-xs {
  padding-right: 10px;
}
@media screen and (max-width: 767px) {
  .padding-right-xs {
    padding-right: 6px;
  }
}
.padding-left-xl {
  padding-left: 120px;
}
@media screen and (max-width: 767px) {
  .padding-left-xl {
    padding-left: 80px;
  }
}
.padding-left-lg {
  padding-left: 60px;
}
@media screen and (max-width: 767px) {
  .padding-left-lg {
    padding-left: 40px;
  }
}
.padding-left-md {
  padding-left: 30px;
}
@media screen and (max-width: 767px) {
  .padding-left-md {
    padding-left: 20px;
  }
}
.padding-left-sm {
  padding-left: 15px;
}
@media screen and (max-width: 767px) {
  .padding-left-sm {
    padding-left: 10px;
  }
}
.padding-left-xs {
  padding-left: 10px;
}
@media screen and (max-width: 767px) {
  .padding-left-xs {
    padding-left: 6px;
  }
}
.padding-top-xl {
  padding-top: 120px;
}
@media screen and (max-width: 767px) {
  .padding-top-xl {
    padding-top: 80px;
  }
}
.padding-top-lg {
  padding-top: 60px;
}
@media screen and (max-width: 767px) {
  .padding-top-lg {
    padding-top: 40px;
  }
}
.padding-top-md {
  padding-top: 30px;
}
@media screen and (max-width: 767px) {
  .padding-top-md {
    padding-top: 20px;
  }
}
.padding-top-sm {
  padding-top: 15px;
}
@media screen and (max-width: 767px) {
  .padding-top-sm {
    padding-top: 10px;
  }
}
.padding-top-xs {
  padding-top: 10px;
}
@media screen and (max-width: 767px) {
  .padding-top-xs {
    padding-top: 6px;
  }
}
