@import './breakpoints.less';

body {
  font-size: 1em;
}

p {
  font-size: 1em;
}

h1 {
  font-size: 2.7em;
  color: #707070;
  font-weight: bold;
  @media screen and (max-width: @breakpoint) {
    font-size: 1.8em;
  }
}

h2 {
  font-size: 2.23em;
  color: #707070;
  font-weight: bold;
  @media screen and (max-width: @breakpoint) {
    font-size: 1.5em;
  }
}

h3 {
  font-size: 1.81em;
  color: #707070;
  font-weight: bold;
  @media screen and (max-width: @breakpoint) {
    font-size: 1.2em;
  }
}

h4 {
  font-size: 1.3em;
  color: #707070;
  font-weight: bold;
  @media screen and (max-width: @breakpoint) {
    font-size: 1em;
  }
}

.text-secondary {
  color: #9f9f9f;
}

.text-success {
  color: #3acc6c !important;
}

.text-alert {
  color: #bf0000 !important;
}
