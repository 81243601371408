@import './breakpoints.less';

// margin bottom
.margin-bottom-xl {
  margin-bottom: 120px;
  @media screen and (max-width: @breakpoint) {
    margin-bottom: 80px;
  }
}

.margin-bottom-lg {
  margin-bottom: 60px;
  @media screen and (max-width: @breakpoint) {
    margin-bottom: 40px;
  }
}

.margin-bottom-md {
  margin-bottom: 30px;
  @media screen and (max-width: @breakpoint) {
    margin-bottom: 20px;
  }
}

.margin-bottom-sm {
  margin-bottom: 15px;
  @media screen and (max-width: @breakpoint) {
    margin-bottom: 10px;
  }
}

.margin-bottom-xs {
  margin-bottom: 10px;
  @media screen and (max-width: @breakpoint) {
    margin-bottom: 6px;
  }
}

// padding bottom
.padding-bottom-xl {
  padding-bottom: 120px;
  @media screen and (max-width: @breakpoint) {
    padding-bottom: 80px;
  }
}

.padding-bottom-lg {
  padding-bottom: 60px;
  @media screen and (max-width: @breakpoint) {
    padding-bottom: 40px;
  }
}

.padding-bottom-md {
  padding-bottom: 30px;
  @media screen and (max-width: @breakpoint) {
    padding-bottom: 20px;
  }
}

.padding-bottom-sm {
  padding-bottom: 15px;
  @media screen and (max-width: @breakpoint) {
    padding-bottom: 10px;
  }
}

.padding-bottom-xs {
  padding-bottom: 10px;
  @media screen and (max-width: @breakpoint) {
    padding-bottom: 6px;
  }
}

// padding right
.padding-right-xl {
  padding-right: 120px;
  @media screen and (max-width: @breakpoint) {
    padding-right: 80px;
  }
}

.padding-right-lg {
  padding-right: 60px;
  @media screen and (max-width: @breakpoint) {
    padding-right: 40px;
  }
}

.padding-right-md {
  padding-right: 30px;
  @media screen and (max-width: @breakpoint) {
    padding-right: 20px;
  }
}

.padding-right-sm {
  padding-right: 15px;
  @media screen and (max-width: @breakpoint) {
    padding-right: 10px;
  }
}

.padding-right-xs {
  padding-right: 10px;
  @media screen and (max-width: @breakpoint) {
    padding-right: 6px;
  }
}

// padding left
.padding-left-xl {
  padding-left: 120px;
  @media screen and (max-width: @breakpoint) {
    padding-left: 80px;
  }
}

.padding-left-lg {
  padding-left: 60px;
  @media screen and (max-width: @breakpoint) {
    padding-left: 40px;
  }
}

.padding-left-md {
  padding-left: 30px;
  @media screen and (max-width: @breakpoint) {
    padding-left: 20px;
  }
}

.padding-left-sm {
  padding-left: 15px;
  @media screen and (max-width: @breakpoint) {
    padding-left: 10px;
  }
}

.padding-left-xs {
  padding-left: 10px;
  @media screen and (max-width: @breakpoint) {
    padding-left: 6px;
  }
}

// padding top
.padding-top-xl {
  padding-top: 120px;
  @media screen and (max-width: @breakpoint) {
    padding-top: 80px;
  }
}

.padding-top-lg {
  padding-top: 60px;
  @media screen and (max-width: @breakpoint) {
    padding-top: 40px;
  }
}

.padding-top-md {
  padding-top: 30px;
  @media screen and (max-width: @breakpoint) {
    padding-top: 20px;
  }
}

.padding-top-sm {
  padding-top: 15px;
  @media screen and (max-width: @breakpoint) {
    padding-top: 10px;
  }
}
.padding-top-xs {
  padding-top: 10px;
  @media screen and (max-width: @breakpoint) {
    padding-top: 6px;
  }
}
