@import '../../../styles/breakpoints.less';

.container-error {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: linear-gradient(45deg, #557cb7, #a1c8fe);

  .container-logo {
    display: flex;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: @breakpoint) {
      justify-content: center;
    }

    img {
      max-height: 25px;

      @media screen and (max-width: @breakpoint) {
        max-height: 17px;
      }
    }

    .title {
      font-size: 30px;
      font-weight: bold;
      line-height: 60px;
      margin: 0px;
      color: #fff;

      @media screen and (max-width: @breakpoint) {
        font-size: 20px;
      }
    }
  }
}

@text-color: #707070;@text-color-secondary: #a9a9a9;@primary-color: #557cb7;@heading-color: #707070;@tabs-horizontal-margin: 0 80px 0 0;@tabs-horizontal-margin-rtl: 0 0 0 80px;@tabs-bar-margin: 0 0 30px 0;@form-vertical-label-padding: 0;@badge-status-size: 10px;@menu-item-vertical-margin: 0px;