@import '../../../../styles/breakpoints.less';

.course-settings-tab-bar {
  .ant-tabs-left-content {
    border-left: 0px;
  }
}

@media screen and (min-width: @breakpoint) {
  .course-settings-wrapper {
    padding-left: 60px;
  }
}

@text-color: #707070;@text-color-secondary: #a9a9a9;@primary-color: #557cb7;@heading-color: #707070;@tabs-horizontal-margin: 0 80px 0 0;@tabs-horizontal-margin-rtl: 0 0 0 80px;@tabs-bar-margin: 0 0 30px 0;@form-vertical-label-padding: 0;@badge-status-size: 10px;@menu-item-vertical-margin: 0px;