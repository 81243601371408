.statistic > h1 {
  display: inline-block;
  margin-bottom: 0px;
  line-height: 1em;
}

.statistic > h4 {
  display: inline-block;
  margin-bottom: 0px;
  font-weight: normal;
}

.statistic > span {
  display: block;
  margin-top: -5px;
}

@media screen and (max-width: 450px) {
  .statistic {
    margin-bottom: 10px;
  }
  .statistic:last-child {
    margin-bottom: 0px;
  }
}

@text-color: #707070;@text-color-secondary: #a9a9a9;@primary-color: #557cb7;@heading-color: #707070;@tabs-horizontal-margin: 0 80px 0 0;@tabs-horizontal-margin-rtl: 0 0 0 80px;@tabs-bar-margin: 0 0 30px 0;@form-vertical-label-padding: 0;@badge-status-size: 10px;@menu-item-vertical-margin: 0px;