@import '../shared.less';

.container-logged-out {
  min-height: 100vh;
  background: linear-gradient(45deg, #557cb7, #a1c8fe);

  .container-main {
    background: #fff;
    border-radius: 0px 80px 80px 0px;

    @media screen and (max-width: 767px) {
      border-radius: 0px;
    }

    .content {
      max-width: 600px;
      margin: 0px auto;
    }
  }
  .container-description {
    .content {
      display: flex;
      min-height: 100vh;
      justify-content: center;
      align-items: center;
    }

    .image {
      width: 100%;
      max-width: 600px;
    }
  }
  .container-go-back {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
  }
  .container-lng {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
  }
}

@text-color: #707070;@text-color-secondary: #a9a9a9;@primary-color: #557cb7;@heading-color: #707070;@tabs-horizontal-margin: 0 80px 0 0;@tabs-horizontal-margin-rtl: 0 0 0 80px;@tabs-bar-margin: 0 0 30px 0;@form-vertical-label-padding: 0;@badge-status-size: 10px;@menu-item-vertical-margin: 0px;