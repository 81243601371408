@import '../shared.less';

.exercise-card {
  .ant-card-body {
    .container-main {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .container-statistics {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 300px;

        @media screen and (max-width: 450px) {
          flex-direction: column;
        }
      }
    }
  }
}

@text-color: #707070;@text-color-secondary: #a9a9a9;@primary-color: #557cb7;@heading-color: #707070;@tabs-horizontal-margin: 0 80px 0 0;@tabs-horizontal-margin-rtl: 0 0 0 80px;@tabs-bar-margin: 0 0 30px 0;@form-vertical-label-padding: 0;@badge-status-size: 10px;@menu-item-vertical-margin: 0px;