@import '../../../../styles/breakpoints.less';
@import '../dimensions.less';

.header {
  width: 100vw;
  height: @header-height;
  display: flex;
  flex-direction: row;
  background: linear-gradient(30deg, #557cb7, #a1c8fe);
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: @breakpoint) {
    height: @header-height-sm;
  }

  .container-logo {
    display: flex;
    order: -1;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media screen and (max-width: @breakpoint) {
      flex: 1;
      order: 0;
    }

    img {
      height: ceil(@header-height*0.3);

      @media screen and (max-width: @breakpoint) {
        height: ceil(@header-height-sm*0.3);
      }
    }

    .title {
      font-size: ceil(@header-height*0.3);
      font-weight: bold;
      line-height: @header-height;
      margin: 0px;
      color: #fff;

      @media screen and (max-width: @breakpoint) {
        font-size: ceil(@header-height-sm*0.3);
      }
    }

    .divider {
      width: 1px;
      height: ceil(@header-height*0.75);
      border-right: 1px solid #fff;

      @media screen and (max-width: @breakpoint) {
        display: none;
      }
    }
  }

  .container-menu {
    flex: 1;
    order: 0;
    overflow: hidden;

    @media screen and (max-width: @breakpoint) {
      width: 120px;
      flex: none;
      order: -1;
    }

    .ant-menu {
      background: none;
      border-bottom: none;
      line-height: @header-height;
      color: #fff;

      @media screen and (max-width: @breakpoint) {
        line-height: @header-height-sm;
      }
    }

    .ant-menu-item {
      font-size: 18px;
      color: #fff;
    }
  }

  .container-account {
    cursor: pointer;
    text-align: center;
    line-height: @header-height;
    order: 1;

    @media screen and (max-width: @breakpoint) {
      line-height: @header-height-sm;
      width: @header-height-sm;
      order: 1;
    }

    .name {
      font-size: 18px;
      color: #fff;

      @media screen and (max-width: @breakpoint) {
        display: none;
      }
    }

    .avatar {
      background-color: #fff;
      color: #557cb7;
    }
  }

  .container-lang {
    cursor: pointer;
    text-align: center;
    line-height: @header-height;
    order: 1;

    @media screen and (max-width: @breakpoint) {
      line-height: @header-height-sm;
      width: @header-height-sm;
      order: 1;
    }
  }
}

@text-color: #707070;@text-color-secondary: #a9a9a9;@primary-color: #557cb7;@heading-color: #707070;@tabs-horizontal-margin: 0 80px 0 0;@tabs-horizontal-margin-rtl: 0 0 0 80px;@tabs-bar-margin: 0 0 30px 0;@form-vertical-label-padding: 0;@badge-status-size: 10px;@menu-item-vertical-margin: 0px;