@import '../shared.less';
@import '../../../styles/breakpoints.less';
@import './dimensions.less';

.container-logged-in {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: @header-height;
  background: linear-gradient(135deg, #f6f6f6, #ffffff);

  @media screen and (max-width: @breakpoint) {
    padding-top: @header-height-sm;
  }

  .content {
    flex: 1;
    padding: 30px 60px;
    @media screen and (max-width: @breakpoint) {
      padding: 30px 30px;
    }
  }
}

.flag {
  height: 16px;
}

@text-color: #707070;@text-color-secondary: #a9a9a9;@primary-color: #557cb7;@heading-color: #707070;@tabs-horizontal-margin: 0 80px 0 0;@tabs-horizontal-margin-rtl: 0 0 0 80px;@tabs-bar-margin: 0 0 30px 0;@form-vertical-label-padding: 0;@badge-status-size: 10px;@menu-item-vertical-margin: 0px;